import ROUTES from './routes.constant';

const { leases, leases_manage, sales, sales_manage } = ROUTES.organisation;

export const routeRedirects = [
  // Leases (Letting Transactions)
  { from: '/transactions/lettings/:id/manage/details', to: `${leases_manage.rootPath}${leases_manage.details}` },
  { from: '/transactions/lettings/:id/manage/management', to: `${leases_manage.rootPath}${leases_manage.management}` },
  { from: '/transactions/lettings/:id/manage/summary', to: `${leases_manage.rootPath}${leases_manage.summary}` },
  { from: '/transactions/lettings/:id/manage', to: `${leases_manage.rootPath}${leases_manage.summary}` },
  { from: '/transactions/lettings', to: `${leases.root}${leases.transactions}` },
  // Sales (Sale Transactions)
  { from: '/transactions/sales/:id/manage/summary', to: `${sales_manage.rootPath}${sales_manage.summary}` },
  { from: '/transactions/sales/:id/manage', to: `${sales_manage.rootPath}${sales_manage.summary}` },
  { from: '/transactions/sales', to: `${sales.root}${sales.transactions}` },
  // IMPORTANT: Ensure that the longest paths are listed first, so that the shorter paths don't match first
].sort((a, b) => b.from.length - a.from.length);
