import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface WeekDay {
  id: Id;
  value: string;
  checked: boolean;
}

export enum ShortWeekDay {
  Monday = 'MO',
  Tuesday = 'TU',
  Wednesday = 'WE',
  Thursday = 'TH',
  Friday = 'FR',
  Saturday = 'SA',
  Sunday = 'SU',
}

export interface TaskEditState {
  type: Id;
  title: string;
  dateFrom?: MaterialUiPickersDate;
  dateTo?: MaterialUiPickersDate;
  timeFrom: string;
  timeTo: string;
  allDay: boolean;
  notes: string;
  repeatRule: string | null;
  repeatInterval: number;
  repeatEnd: boolean;
  repeatEndsOn: MaterialUiPickersDate | null;
  repeatMonthlyByDays: Array<WeekDay>;
  repeatMonthlyRadio: Id;
  users: Array<UserOption>;
  companies: Array<CompanyOption>;
  contacts: Array<ContactOption>;
  disposals: Array<DisposalOption>;
  requirements: Array<RequirementOption>;
  societyMembers: Array<SocietyMemberOption>;
  societyAcquisitions: Array<SocietyAcquisitionOption>;
  societyDisposals: Array<SocietyDisposalOption>;
  editTaskLoading: boolean;
}

export interface TaskEditErrorState {
  title: boolean;
  type: boolean;
  dateFrom: boolean;
  dateTo: boolean;
  footerError: boolean;
  footerErrorText: string;
}

export interface UserOption {
  name: string;
  id: Id;
  image: string;
  forename: string;
  surname: string;
  theme: {
    backgroundColour: string;
    textColour: string;
  };
}

export interface CompanyOption {
  name: string;
  id: Id;
  address?: string;
}

export interface ContactOption {
  name: string;
  id: Id;
  email: string;
}

export interface DisposalOption {
  name: string;
  id: Id;
  address: string;
  image: string;
}

export interface RequirementOption {
  id: Id;
  name: string;
  client_name: string;
}

export interface SocietyMemberOption {
  id: Id;
  name: string;
  organisation_name: string;
}

export interface SocietyAcquisitionOption {
  id: Id;
  name: string;
  organisation?: string;
}

export interface SocietyDisposalOption {
  id: Id;
  name: string;
  organisation: string;
  email: string;
}
