import { FC, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { TaskShowItem } from '@apis/tasksApi.types';
import { useOverlays, UseOverlaysRecord } from '@core/hooks/overlays';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { ViewingsEditDrawer } from '@shared/drawers/viewings-edit-drawer';
import { ViewingsFeedbackDrawer } from '@shared/drawers/viewings-feedback-drawer';
import { ViewingsViewDrawer } from '@shared/drawers/viewings-view-drawer';
import { getQueries, getQueryValueString } from '@shared/utils/common';

import { styles } from './Viewings.styles';

export interface ViewingsComponentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type ViewingsOverlays = UseOverlaysRecord<{
  create: boolean;
  details: string;
  edit: string;
  feedback: string;
}>;

const ViewingsComponent: FC<ViewingsComponentProps> = ({ classes }) => {
  const initQueries = useMemo(() => getQueries(), []);

  const { overlays, setOverlays } = useOverlays<ViewingsOverlays>({
    create: !!initQueries.create,
    details: getQueryValueString('details', initQueries),
    edit: getQueryValueString('edit', initQueries),
    feedback: getQueryValueString('feedback', initQueries),
  });

  // Details
  const handleOpenViewDetails = (detail: TaskShowItem) => {
    setOverlays((_prev) => ({ ..._prev, details: detail.id.toString(), test: '' }));
  };

  const handleCloseViewDetails = () => setOverlays((_prev) => ({ ..._prev, details: '' }));

  // Edit
  const handleOpenViewEdit = (viewId: Id) => {
    handleCloseViewDetails();
    setOverlays((_prev) => ({ ..._prev, edit: viewId.toString() }));
  };

  const handleCloseViewEdit = () => setOverlays((_prev) => ({ ..._prev, edit: '' }));

  // Feedback
  const handleOpenViewFeedback = (viewId: Id) => {
    handleCloseViewDetails();
    setOverlays((_prev) => ({ ..._prev, feedback: viewId.toString() }));
  };

  const handleCloseViewFeedback = () => setOverlays((_prev) => ({ ..._prev, feedback: '' }));

  return (
    <div className={classes.root}>
      <ViewingsViewDrawer
        viewId={overlays.details}
        open={!!overlays.details}
        onClose={handleCloseViewDetails}
        onEditOpen={handleOpenViewEdit}
        onFeedbackOpen={handleOpenViewFeedback}
      />
      <ViewingsEditDrawer
        viewId={overlays.edit}
        open={!!overlays.edit}
        onDetailsOpen={handleOpenViewDetails}
        onClose={handleCloseViewEdit}
      />
      <ViewingsFeedbackDrawer
        viewId={overlays.feedback}
        open={!!overlays.feedback}
        onDetailsOpen={handleOpenViewDetails}
        onClose={handleCloseViewFeedback}
      />
    </div>
  );
};

export const Viewings = withStyles(styles)(ViewingsComponent);
